.import-wrapper {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}

.import-wrapper .styled-file-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}

.import-wrapper .styled-file-input span {
    /*margin-left: 10px;*/
}

.import-wrapper .file-input {
    display: none;
}
